@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}

.upper-case {
  text-transform: uppercase;
}

.home .card {
  margin-right: 1rem;
  margin-left: 1rem;
}
.home .card .card-text {
  width: 19rem;
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 900px;
  padding-top: 100px;
}

.curve {
  position: absolute;
  height: 250px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  -webkit-transform: translate(85%, 60%);
          transform: translate(85%, 60%);
  background-color: white;
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #3c31dd;
  -webkit-transform: translate(-4%, 40%);
          transform: translate(-4%, 40%);
  z-index: -1;
}

.blue {
  background: #3c31dd;
}

.manual-grid {
  display: grid;
}

.two-cols {
  grid-template-columns: auto auto;
}

footer .links {
  height: 20rem;
}

.copy-right-line {
  background-color: black;
  color: white;
  height: 2rem;
  padding-top: 0.5rem;
  font-size: 0.7rem;
}

section {
  margin-top: 1rem;
}

.service-links a {
  text-decoration: none;
  margin-right: 1rem;
}

/*# sourceMappingURL=main.css.map */

